import { researchCollection, queriesCollection } from "../../lib/firebase";
import {
  query,
  getDocs,
  getDoc,
  setDoc,
  doc,
  orderBy,
  addDoc,
  deleteDoc,
  where,
  limit,
  startAfter,
} from "firebase/firestore";
import axios from "axios";

const baseUrl =
  process.env.NODE_ENV === "production"
    ? "https://functions-5l65dfr7ha-uc.a.run.app"
    : "http://127.0.0.1:2500";

export const saveSelected = async ({ commit, getters }) => {
  commit("setSavingDocs", true);

  console.log("starting save selected!");

  let docs = getters.getQueryDocs;

  let requests = docs.map((row) => {
    return new Promise((resolve) => {
      setDoc(
        doc(researchCollection, row.id),
        { selected: row.selected},
        { merge: true }
      ).then(() => resolve());
    });
  });

  Promise.all(requests).then(() => {
    commit("setSavingDocs", false);
  });
  
};

export const setLastUpdateQuery = async ({ dispatch, state }, { qid }) => {
  const dateOfUpdate = new Date();

  setDoc(
    doc(queriesCollection, qid),
    { timeExported: dateOfUpdate, exportedByUser: state.userProfile.uid },
    { merge: true }
  );

  dispatch("loadCurrentQuery", qid);
};

export const loadCurrentQuery = async ({ commit }, qid) => {
  await getDoc(doc(queriesCollection, qid)).then((doc) => {
    commit("setCurrentQuery", doc.data());
  });
};

export const loadQueries = async ({ commit }) => {
  commit("setLoadingQueries", true);

  const snapshot = await getDocs(
    query(queriesCollection, orderBy("timestamp", "desc"))
  );
  var docs = [];
  snapshot.forEach((doc) => {
    const curDoc = doc.data();
    curDoc.id = doc.id;
    docs.push(curDoc);
  });

  commit("setQueries", docs);
  commit("setLoadingQueries", false);
};

export const makeQuery = async (
  { commit },
  { parameters, type }
) => {
  commit("setLoadingQuery", true);

  axios
    .post(baseUrl + "/makeQuery", {
      parameters: parameters,
      type: type,
    })
    .then(function (response) {
      commit("setPubmedQuery", response.data.query);
      commit("setLoadingQuery", false);
    })
    .catch(function (error) {
      console.log(error);
      commit("setLoadingQuery", false);
    });
};

export const submitQuery = async (
  { commit, dispatch, state },
  { query, parameters, type }
) => {
  commit("setLoadingQueries", true);

  const docRef = await addDoc(
    queriesCollection,
    {
      parameters: parameters,
      type: type,
      query: query,
      uid: state.userProfile.uid,
      email: state.userProfile.email,
      timestamp: new Date(),
      response: "",
      completed: false,
    },
    { merge: true }
  ).catch((error) => {
    console.error("Error adding document: ", error);
  });

  dispatch("loadQueries");
  dispatch("startAnalysis", {
    query: query,
    parameters: parameters,
    type: type,
    qid: docRef.id,
    email: state.userProfile.email,
  });
};

export const deleteSelectedQuery = async ({ commit, dispatch }, qid) => {
  commit("setLoadingQueries", true);
  await deleteDoc(doc(queriesCollection, qid));
  dispatch("loadQueries");
};

export const startAnalysis = async (
  _,
  { query, parameters, type, qid, email }
) => {
  axios
    .post(`${baseUrl}/runQuery`, {
      query: query,
      parameters: parameters,
      type: type,
      qid: qid,
      email: email,
    })
    .then((response) => {
      return response;
    });
};

export const loadQueryDocs = async (
  { commit, state },
  { qid, page, pageSize }
) => {
  commit("setLoadingQueryDocs", true);

  const currentQuery = await getDoc(doc(queriesCollection, qid));
  const currentData = currentQuery.data();

  commit("setCurrentQuery", currentData);

  // set total number of docs
  const buf = await getDocs(
    query(
      researchCollection,
      where("qid", "==", qid),
      where("prediction", ">", 0.5)
    )
  );
  const totalDocs = buf.size;
  commit("setTotalDocs", totalDocs);

  const foundDocs = [];

  let baseQuery = query(
    researchCollection,
    where("qid", "==", qid),
    where("prediction", ">", 0.5),
    orderBy("prediction", "desc"),
    limit(pageSize) // use pageSize instead of hardcoded value
  );

  if (page > 1 && state.lastVisibleDoc) {
    baseQuery = query(baseQuery, startAfter(state.lastVisibleDoc));
  }

  const snapshot = await getDocs(baseQuery);

  if (!snapshot.empty) {
    const lastDoc = snapshot.docs[snapshot.docs.length - 1];
    commit("setLastVisibleDoc", lastDoc);
  }

  snapshot.forEach((doc) => {
    const curDoc = doc.data();
    curDoc.id = doc.id;
    curDoc.selected = curDoc.selected ? curDoc.selected : false;
    foundDocs.push(curDoc);
  });

  //sort by predicted similarity
  foundDocs.sort((a, b) => (a.prediction < b.prediction ? 1 : -1));

  commit("setQueryDocs", foundDocs);
  commit("setLoadingQueryDocs", false);
};
